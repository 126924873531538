import * as Sentry from '@sentry/browser';
import { BrowserTracing } from '@sentry/tracing';

export const initSentry = () => {
  Sentry.init({
    dsn: 'https://a85e8b63b29e4a7e952f9f9b6fbf8781@o67475.ingest.sentry.io/4503894151004160',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0,
    beforeSend(event) {
      const filteredReaErrors =
        event.exception?.values?.filter((err) => err.type === 'ReaError') ||
        undefined;

      const eventOrNull: Sentry.Event | null =
        filteredReaErrors && filteredReaErrors.length > 0
          ? {
              ...event,
              exception: {
                ...event.exception,
                values: filteredReaErrors,
              },
            }
          : null;

      return eventOrNull;
    },
  });
};
