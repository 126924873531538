import {
  KEY_NAME,
  BRAZE_MODE,
  EDM_KEY_NAME,
  BRAZE_LOGGER_URL,
  REAUID,
  ADID,
} from './constants';
import * as Sentry from '@sentry/browser';
import { ReaError } from './ReaError';

const init = () => {
  try {
    const reaLeadId = find_id(KEY_NAME);
    const reauid = find_id(REAUID);
    const adid = find_id(ADID);

    const edm_conversion_link = find_edm_conversion_link();
    const edm_conversion_link_present = edm_conversion_link !== null;

    if (reaLeadId) {
      set_is_braze(false);
      set_id(KEY_NAME, reaLeadId);
    }

    if (reauid) {
      set_is_braze(false);
      set_id(REAUID, reauid);
    }

    if (adid) {
      set_is_braze(false);
      set_id(ADID, adid);
    }

    if (edm_conversion_link_present) {
      set_is_braze(true);
      set_edm_conversion_link(edm_conversion_link);
    }
  } catch (error) {
    reportSentryError(error, 'Unknown error initialising conversion scripts');

    throw error;
  }
};

const setLocalStorageAndLog = (key: string, value?: string) => {
  if (!value || typeof localStorage === 'undefined') return;

  try {
    localStorage.setItem(key, value);
  } catch (error) {
    console.error(
      `Error setting ${key} into localStorage. Key: ${key}; Value: ${value}`,
      error,
    );

    reportSentryError(error, 'Unknown localStorage.setItem error');

    fire_braze_logger({
      clientsideEvent: 'FAILED_TO_SET_LOCALSTORAGE_ITEM',
      key,
      value,
      error:
        error instanceof Error
          ? { name: error.name, message: error.message }
          : error,
    });
  }
};

const get_lead_id = () => localStorage.getItem(KEY_NAME);

const get_reauid = () => localStorage.getItem(REAUID);

const get_adid = () => localStorage.getItem(ADID);

const find_edm_conversion_link = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  const encodedConversionLink = queryParams.get(EDM_KEY_NAME);

  return encodedConversionLink
    ? decodeURIComponent(encodedConversionLink)
    : null;
};

const set_edm_conversion_link = (value: string) =>
  setLocalStorageAndLog(EDM_KEY_NAME, value);

const get_edm_conversion_link = () => localStorage.getItem(EDM_KEY_NAME);

const set_id = (key: string, value: string | undefined) =>
  setLocalStorageAndLog(key, value);

const find_id = (key_name: string): string | undefined => {
  let hash = window.location.hash;

  while (hash.charAt(0) == '#') hash = hash.substring(1, hash.length);
  const hashContainsKey = (key: string) => hash.indexOf(key) !== -1;
  const idSearchParam = new URLSearchParams(window.location.search).get(
    key_name,
  );

  if (hashContainsKey(key_name + '=')) {
    return find_key_value_hash(hash, key_name);
  } else if (idSearchParam != undefined) {
    return idSearchParam;
  } else {
    return undefined;
  }
};

const set_is_braze = (is_braze: boolean) =>
  setLocalStorageAndLog(BRAZE_MODE, JSON.stringify(is_braze));

const get_is_braze = () => {
  const localStorageBrazeMode = localStorage.getItem(BRAZE_MODE) == 'true';
  const conversionLinkInUrl = !!find_edm_conversion_link();

  return localStorageBrazeMode || conversionLinkInUrl;
};

const find_key_value_hash = (hash: string, key_name: string) => {
  let c_start, c_end;
  let emptyHash = true;

  c_start = hash.indexOf(key_name + '=');

  if (c_start != -1) {
    if (c_start != 0) emptyHash = false;

    c_start = c_start + key_name.length + 1;
    c_end = hash.indexOf('&', c_start);

    if (c_end == -1) {
      c_end = hash.length;
    } else {
      emptyHash = false;
    }
    if (emptyHash) {
      history.pushState(
        '',
        document.title,
        window.location.pathname + window.location.search,
      );
    }
    return unescape(hash.substring(c_start, c_end));
  }
};

const get_sas_lead_tracking_url = (
  advertiser_id: string,
  mid_path_component: string,
  reauid_path_component: string,
  adid_path_component: string,
  action = 'LeadComplete',
) => {
  return `https://sasinator.realestate.com.au/rea/count/advid=${advertiser_id}${mid_path_component}${reauid_path_component}${adid_path_component}/actname=${action}`;
};

const promisify = (f: () => string | null): Promise<string> => {
  const result = f();
  if (result == null) {
    return Promise.reject('cannot promisify the function');
  } else {
    return Promise.resolve(result);
  }
};

const retrieveTrackingLink = async (): Promise<string> => {
  return promisify(find_edm_conversion_link).catch(() => {
    return promisify(get_edm_conversion_link);
  });
};

const getCurrentEnvironment = async () => ({
  brazeMode: get_is_braze(),
  edmConversionLink: await retrieveTrackingLink(),
  hasLocalStorage: typeof localStorage !== 'undefined',
  edmConversionLinkFromClientLocalStorage: get_edm_conversion_link(),
  pageUrl: window.location.href,
});

const sendConversionToBraze = (conversionLink: string) =>
  fetch(conversionLink, { method: 'GET' })
    .then(async (response) => {
      if (!response.ok) {
        fire_braze_logger({ FAILED_RESPONSE: JSON.stringify(response) });
        throw new Error(
          `HTTP error! status: ${response.status} ${await response.text()}`,
        );
      }
      return (await response.json()) as Record<string, unknown>;
    })
    .catch((error) => {
      reportSentryError(
        error,
        `Error reporting conversion to braze ${conversionLink}`,
      );
      throw error;
    });

const fire_braze_conversion = async (): Promise<unknown> => {
  const currentEnvironment = await getCurrentEnvironment();

  fire_braze_logger(currentEnvironment);

  return await sendConversionToBraze(currentEnvironment.edmConversionLink);
};

const fire_braze_logger = (paylaod: Record<string, unknown>) => {
  fetch(BRAZE_LOGGER_URL, { method: 'POST', body: JSON.stringify(paylaod) })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .catch((error) => {
      console.error('Error firing braze logger', error);

      reportSentryError(error, 'Error firing braze logger');
    });
};

const reportSentryError = (error: unknown, fallbackErrorMessage: string) => {
  const err =
    error instanceof Error
      ? new ReaError(`${error.name}: ${error.message}`, error.stack)
      : new ReaError(fallbackErrorMessage);

  Sentry.captureException(err);
};

export {
  set_id,
  get_lead_id,
  get_reauid,
  get_adid,
  get_edm_conversion_link,
  find_edm_conversion_link,
  set_edm_conversion_link,
  find_id,
  get_sas_lead_tracking_url,
  fire_braze_conversion,
  get_is_braze,
  init,
  fire_braze_logger,
  setLocalStorageAndLog,
};
