import { initSentry } from './initSentry';
import {
  fire_braze_conversion,
  get_is_braze,
  get_lead_id,
  get_reauid,
  get_adid,
  get_sas_lead_tracking_url,
  init,
} from './utils';

declare global {
  interface Window {
    REA: Record<string, unknown>;
  }
}

initSentry();

//Public function
window.REA = window.REA || {};
window.REA.track_conversion = async (advertiser: string, action?: string) => {
  if (typeof localStorage === 'undefined') {
    return;
  }

  if (get_is_braze()) {
    await fire_braze_conversion();
  } else {
    const leadId = get_lead_id();
    const mid_path_component = leadId ? `/mid=${leadId}` : '';

    const reauid = get_reauid();
    const reauid_path_component = reauid ? `/reauid=${reauid}` : '';

    const adid = get_adid();
    const adid_path_component = adid ? `/adid=${adid}` : '';

    fire_sas_conversion(
      advertiser,
      mid_path_component,
      reauid_path_component,
      adid_path_component,
      action,
    );
  }
};

const fire_sas_conversion = (
  advertiser: string,
  mid_path_component: string,
  reauid_path_component: string,
  adid_path_component: string,
  action?: string,
) => {
  const advertiser_ids = advertiser.toString().split(',');
  advertiser_ids.forEach((advertiser_id) => {
    if (advertiser_id) {
      new Image().src = get_sas_lead_tracking_url(
        advertiser_id,
        mid_path_component,
        reauid_path_component,
        adid_path_component,
        action,
      );
    }
  });
};

//Init:
init();
