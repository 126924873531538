export class ReaError extends Error {
  constructor(message: string, stack?: string) {
    super(message);
    this.name = 'ReaError';

    if (stack) {
      this.stack = stack;
    }
  }
}
